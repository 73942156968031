import React from 'react'
import { Link } from 'gatsby'

const Footer = ({ siteTitle }) => (
  <footer>

    <div className="copyright-area">
      <div className="column">
        FIRSTBOND FRANCE © {new Date().getFullYear()}
        {/*<br/>
        <div className="site-reference"><a href="https://www.stellaire.fr/">Powered by Stellaire SAS</a></div>*/}
      </div>
      <div className="column">
        {/*<div className="site-reference"><a href="https://www.stellaire.fr/">Powered by Stellaire SAS</a></div>*/}
        <div className="mentions"><Link to="/cgv/">CGV</Link> - <Link to="/legal/">Mentions légales</Link></div>
      </div>
    </div>

  </footer>
)

export default Footer
