module.exports = {
  siteMetadata: {
    title: 'Firstbond | Distributeur de Panneaux Composite Aluminium',
    siteUrl: 'https://firstbond.boutique',
    products: {
      title: 'Firstbond - Produits',
      siteUrl: 'https://firstbond.boutique/products',
    }
  },
  plugins: [
    'gatsby-plugin-react-helmet',
    `gatsby-plugin-sass`,
    `gatsby-remark-copy-linked-files`,
    `gatsby-plugin-image`,
    `gatsby-plugin-sharp`,
    `gatsby-plugin-netlify`,
    `gatsby-transformer-sharp`,
    `gatsby-plugin-postcss`,
    {
      resolve: `gatsby-plugin-fastify`,
      options: {
        /* discussed below */
        features: {
          redirects: true,
        },
      },
    },
    {
      resolve: `gatsby-transformer-remark`,
      options: {
        plugins: [
          {
            resolve: `gatsby-remark-images`,
            options: {
              // It's important to specify the maxWidth (in pixels) of
              // the content container as this plugin uses this as the
              // base for generating different widths of each image.
              maxWidth: 1080,
            },
          },
        ],
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/src`,
        name: 'images',
      },
    },
    {
      resolve: `gatsby-plugin-sitemap`,
    },
    {
      resolve: `gatsby-plugin-nprogress`,
      options: {
        // Setting a color is optional.
        color: `green`,
        // Disable the loading spinner.
        showSpinner: false,
      },
    },

    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        name: 'Firstbond',
        description: 'Firstbond website',
        short_name: 'Firstbond',
        start_url: '/',
        background_color: '#FFF', //background color in splash screen
        theme_color: '#FFF', //navbar color
        lang: 'en',
        display: 'standalone',
        icon: 'src/images/logo_firstbond.png',
        icons: [
          // Add or remove icon sizes as desired
          {
            src: '/favicons/android-chrome-192x192.png',
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: '/favicons/android-chrome-384x384.png',
            sizes: '384x384',
            type: 'image/png',
          },
          {
            src: '/favicons/android-chrome-512x512.png',
            sizes: '512x512',
            type: 'image/png',
          },
        ],
      },
    },
    /* Must be placed at the end */
    'gatsby-plugin-offline',
  ],
}
